import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { environment } from "@env/environment";
import { AuthenticationService } from "@app/authentication.service";
import { Observable, of, Observer } from "rxjs";

@Injectable({
  providedIn: "root",
})
export class UserService {
  constructor(
    private http: HttpClient,
    private router: Router,
    private auth: AuthenticationService
  ) {}

  getAllUsers(): Observable<any> {
    let url = `${environment.api_url}/api/getUser`;
    return this.http.get(url);
  }

  getManagers(): Observable<any> {
    let url = `${environment.api_url}/api/getManager`;
    return this.http.get(url);
  }

  getManagersDirectors(): Observable<any> {
    let url = `${environment.api_url}/api/getManagerDirector`;
    return this.http.get(url);
  }

  addNewUser(user: any): Observable<any> {
    let url = `${environment.api_url}/api/addUser`;
    return this.http.post(url, user, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  removeUser(userId: number) {
    let url = `${environment.api_url}/api/removeUser/${userId}`;
    return this.http.get(url);
  }
  activeUser(userId: number) {
    let url = `${environment.api_url}/api/activeUser/${userId}`;
    return this.http.get(url);
  }
  inactiveUser(userId: number) {
    let url = `${environment.api_url}/api/inactiveUser/${userId}`;
    return this.http.get(url);
  }

  emailValidate(userEmail) {
    let url = `${environment.api_url}/api/user/validate/email`;
    return this.http.post(
      url,
      {
        email: userEmail,
      },
      {
        headers: {
          "Content-Type": "application/json",
        },
      }
    );
  }

  getUser(userId) {
    let url = `${environment.api_url}/api/editUser/${userId}`;
    return this.http.get(url);
  }

  updateUser(userData) {
    let url = `${environment.api_url}/api/user/update`;
    return this.http.post(url, userData, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  getRole() {
    let url = `${environment.api_url}/api/role`;
    return this.http.get(url);
  }
}
