// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // api_url : "http://203.85.37.54:8000",
  // api_url : "http://127.0.0.1:8000",
  api_url : "https://epfrlite-api.legacyfa-asia.com",
  // api_url : "http://localhost/lfa/backend-epfr",
  // api_url : "http://localhost/lfa/backend-epfr",
  // api_url : "https://epfr.legacyfa-asia.com:8000",
  singpass_url: "http://localhost:3002"
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLoI.
