import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Router } from "@angular/router";
import { map } from "rxjs/operators";
import { Observable, of } from "rxjs";
import { environment } from "@env/environment";
import { Location } from "@angular/common";

interface TokenResponse {
  token: string;
  user: any;
  permission: any;
}

export interface TokenPayload {
  email: string;
  password: string;
  city: any;
  country: any;
  ip: any;
  latitude: any;
  longitude: any;
}

export interface UserPayload {
  id: number;
  role: number;
  approvable: number;
  email: string;
}

export interface PermissionPayload {
  id: number;
  permission_id: number;
  permission_name: string;
  role_id: number;
  role_name: string;
}

@Injectable({
  providedIn: "root",
})
export class AuthenticationService {
  private token: string;
  private permission: any;
  private user: UserPayload;
  email = "";
  constructor(
    private http: HttpClient,
    private router: Router,
    private location: Location
  ) {}

  public saveToken(token: string) {
    localStorage.setItem("usertoken", token);
    this.token = token;
  }

  public saveUser(user: UserPayload) {
    this.user = user;
    localStorage.setItem("user", JSON.stringify(user));
    // localStorage.removeItem(this.user.id.toString());
  }

  public savePermission(permission: PermissionPayload) {
    this.permission = permission;
    localStorage.setItem("userPermission", JSON.stringify(permission));
  }

  public getPermission() {
    if (!this.permission) {
      this.permission = localStorage.getItem("userPermission");
    }
    return typeof this.permission === "string"
      ? JSON.parse(this.permission)
      : this.permission;
  }

  public checkPermission(perm = null) {
    var returns = false;
    if (perm) {
      var permsData = this.getPermission();
      if (permsData) {
        var result = this.getPermission()
          .filter((x) => x.permission_name === perm)
          .map((x) => x.checked);
        if (result >= 1) {
          returns = true;
        }
      }
    }

    return returns;
  }

  public redirectPermission(perm = null) {
    var returns = false;
    if (perm) {
      var permsData = this.getPermission();
      if (permsData) {
        var result = this.getPermission()
          .filter((x) => x.permission_name === perm)
          .map((x) => x.checked);
        if (result >= 1) {
          returns = true;
        }
      }
    }
    if (returns == false) {
      this.router.navigateByUrl(`authenticated/pfr/intro`);
      // this.location.back();
    }
    // return returns;
  }

  public getToken() {
    if (!this.token || this.token == "") {
      this.token = localStorage.getItem("usertoken");
    }
    return this.token;
  }

  public getUser() {
    if (!this.user || this.user == null) {
      let user = localStorage.getItem("user");
      if (user == "" || user == null) {
        this.user = {
          id: 0,
          role: 0,
          approvable: 0,
          email: "",
        };
      } else {
        this.user = JSON.parse(user);
        // console.log('this.user',this.user.id)
        // localStorage.removeItem(this.user['id']);
      }
    }
    return this.user;
  }

  public isLoggedIn() {
    if (
      !this.getToken ||
      this.getToken() == "" ||
      this.getToken() == null ||
      this.getToken() == undefined
    ) {
      return false;
    }
    return true;
  }

  public register(user: TokenPayload): Observable<any> {
    console.log(user);
    return this.http.post(`api/register`, user, {
      headers: {
        "Content-Type": "application/json",
      },
    });
  }

  // public test (user : TokenPayload) : Observable<any> {
  //   return this.http.get(`${environment.api_url}/login`);
  // }

  public login(user: TokenPayload): Observable<any> {
    console.log("user", user);
    let url = `${environment.api_url}/api/login`;
    const base = this.http.post(url, user, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });

    this.email = user.email;

    const request = base.pipe(
      map((data: TokenResponse) => {
        if (data.token) {
          this.saveToken(data.token);
        }
        if (data.user) {
          this.saveUser(data.user);
        }
        return data;
      })
    );

    return request;
  }

  public verify(code) {
    let url = `${environment.api_url}/api/verify`;
    let req = this.http
      .post(
        url,
        {
          email: this.email,
          code: code,
        },
        {
          headers: {
            "Content-Type": "application/json",
            "Access-Control-Allow-Origin": "*",
          },
        }
      )
      .pipe(
        map((data: TokenResponse) => {
          if (data.token) {
            this.saveToken(data.token);
          }
          if (data.user) {
            this.saveUser(data.user);
          }

          if (data.permission) {
            this.savePermission(data.permission);
          }
          return data;
        })
      );
    return req;
  }

  public resendCode() {
    let url = `${environment.api_url}/api/resend`;
    let req = this.http.post(
      url,
      {
        email: this.email,
      },
      {
        headers: {
          "Content-Type": "application/json",
          "Access-Control-Allow-Origin": "*",
        },
      }
    );
    return req;
  }

  public sendResetLink(user): Observable<any> {
    let url = `${environment.api_url}/api/reset`;
    return this.http.post(url, user, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  }

  public resetPassword(token, password) {
    let payload = {
      token: token,
      password: password,
    };
    let url = `${environment.api_url}/api/confirm`;
    return this.http.post(url, payload, {
      headers: {
        "Content-Type": "application/json",
        "Access-Control-Allow-Origin": "*",
      },
    });
  }

  public logout() {
    this.token = "";
    this.user = undefined;
    localStorage.setItem("usertoken", this.token);
    localStorage.setItem("user", "");
    localStorage.setItem("userPermission", "");
    this.router.navigateByUrl("/");
    console.log("logout");
  }
}
