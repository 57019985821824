import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { HttpClientModule} from '@angular/common/http';
import { FormBuilder, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ToastrModule } from 'ngx-toastr';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AuthenticationService } from './authentication.service';
import { PermissionService } from './permission.service';
import { AuthGuardService } from './auth-guard.service'
import { UserService } from './module/authenticated/modules/user/user.service';
import { NgxLoadingModule } from 'ngx-loading';
import { CKEditorModule } from '@ckeditor/ckeditor5-angular';
import { NgxSmartModalModule } from 'ngx-smart-modal';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { AuthInterceptor } from './module/@shared/interceptor/auth-interceptor.service';
import { StorageService } from './module/@shared/service/storage.service';
import { DatePipe } from '@angular/common';
import { NonNumberRemoverPipe } from './module/@shared/pipe/NonNumberRemover.pipe';

@NgModule({
  declarations: [
    AppComponent,
    // Select2Component
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    ReactiveFormsModule,
    ToastrModule.forRoot({
      closeButton : true,
      maxOpened : 1,
      extendedTimeOut : 0,
      autoDismiss: true,
      positionClass: 'toast-bottom-left',
    }),
    BrowserAnimationsModule,
    NgxLoadingModule.forRoot({}),
    CKEditorModule,
    NgxSmartModalModule.forRoot(),
  ],
  providers: [
    AuthenticationService,
    AuthGuardService,
    FormBuilder,
    UserService,
    StorageService,
    PermissionService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    },
    DatePipe
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
